import { dateCompare } from "@CommonJS/common.js";

import classNames from "classnames/bind";

import style from "./Career.module.scss";

const cx = classNames.bind(style);

type Props = {
  title?: any;
  name?: any;
  id?: any;
};

const Career: React.FC<Props> = ({ title, name, id }: Props) => {
  return (
    <>
      <div className={cx("main_career")} id={id}>
        <div className={cx("main_career_inner")}>
          <h3 className={cx("main_career_title")}>경력</h3>

          <div className={cx("main_career_history")}>
            <h4 className={cx("main_history_title")}>
              ⭐️ 총 경력 : {dateCompare("2013-10-01", new Date())}
            </h4>

            <ol className={cx("main_history_list", "type_basic")}>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2016.04. ~ 현재({dateCompare("2016-04-18", new Date())})
                  </em>

                  <strong className={cx("main_head_title", "type_company")}>
                    <a
                      href="https://nts-corp.com/"
                      className={cx("main_title_link")}
                      target="_blank"
                    >
                      🔗 엔테크서비스
                    </a>
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    UI 개발 / 과장
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://weather.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 날씨 UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2023.08. ~ ({dateCompare("2023-08-01", new Date())})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%, 서비스 리딩
                      </span>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://blog.naver.com/naver_weather/223682318257"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 미세 먼지 페이지 개편
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://blog.naver.com/naver_weather/223622901625"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 날씨 제보 톡 Beta 서비스 출시
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://blog.naver.com/naver_weather/223501416151"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 날씨 지도 ‘강수량 ・ 적설량 관측 정보' 신규 추가
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://blog.naver.com/naver_weather/223501389181"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 재해, 재난 지도 페이지 영상 리뉴얼
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://blog.naver.com/naver_weather/223286477633"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 관심 지역 설정 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          블록형 템플릿 개선 및 유지 보수
                        </li>
                        <li className={cx("main_list_item")}>
                          기타 다수 유지 보수 업무 수행
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      Vintage City UI 개발
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2022.07. ~ 2023.08.(
                        {dateCompare("2022-07-01", "2023-08-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%, 서비스 리딩
                      </span>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          판매자 랭킹 추가
                        </li>
                        <li className={cx("main_list_item")}>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            스냅 - 목록
                          </span>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            스냅 - 상세
                          </span>
                        </li>
                        <li className={cx("main_list_item")}>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            플레이스 - 목록
                          </span>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            상세
                          </span>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            지도
                          </span>
                        </li>
                        <li className={cx("main_list_item")}>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            다국어 추가
                          </span>
                        </li>
                        <li className={cx("main_list_item")}>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            MSS 상품 추가
                          </span>
                        </li>
                        <li className={cx("main_list_item")}>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            서비스 소개 개편
                          </span>
                        </li>
                        <li className={cx("main_list_item")}>
                          <span
                            className={cx("main_item_link", "type_service")}
                          >
                            판매자 센터 개편
                          </span>
                        </li>
                        <li className={cx("main_list_item")}>
                          기타 다수 유지 보수 업무 수행
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://searchad.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 광고 UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2022.04. ~ 2022.05.(
                        {dateCompare("2022-04-01", "2022-05-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 50%
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        유지 보수
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://finance.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 증권 UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2022.01. ~ 2022.04.(
                        {dateCompare("2022-01-01", "2022-04-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%, 서비스 리딩
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        유지 보수
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://land.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 부동산 UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2018.11. ~ 2022.01.(
                        {dateCompare("2018-11-01", "2022-01-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%, 서비스 리딩
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        서비스 UI 개발 전체 리딩 및 다수 구축 프로젝트 및 유지
                        보수 업무 수행
                      </p>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://view.gitlab.cony.kr/portfolio/portfolio.old/-/raw/c6a89afc0b00699b57894e057797a01a474ad9ee/new/land/detail/detail.html"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 모바일 매물 상세 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          개발 계획 서비스 구축
                          <div className={cx("main_item_about", "type_more")}>
                            <a
                              href="https://new.land.naver.com/complexes?ms=37.275886,127.1335728,16&a=APT:PRE&e=RETAIL&h=124&ad=true&developmentPlanType=rail&developmentPlanId=1&gid=467497"
                              className={cx("main_item_link", "type_service")}
                              target="_blank"
                            >
                              🔗 PC
                            </a>
                            <a
                              href="https://m.land.naver.com/developmentplan/info/rail/1"
                              className={cx("main_item_link", "type_service")}
                              target="_blank"
                            >
                              🔗 Mobile
                            </a>
                          </div>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://fin.land.naver.com/"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 보증보험 서비스 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://fin.land.naver.com/lessor"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 임대인 의무 보증 서비스 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          공시가격 서비스 구축
                          <div className={cx("main_item_about", "type_more")}>
                            <a
                              href="https://new.land.naver.com/complexes/27073?ms=37.531459,126.651523,17&a=APT:ABYG:JGC&e=RETAIL"
                              className={cx("main_about_link", "type_basic")}
                              target="_blank"
                            >
                              🔗 PC : 좌측 상단 "동호수 / 공시 가격" 탭 클릭
                            </a>
                            <a
                              href="https://m.land.naver.com/complex/landprice?hscpNo=27073"
                              className={cx("main_about_link", "type_basic")}
                              target="_blank"
                            >
                              🔗 Mobile
                            </a>
                          </div>
                        </li>
                        <li className={cx("main_list_item")}>
                          금융 기관 콜라보 서비스 구축(프로젝트 홀딩으로 인한
                          비공개)
                        </li>
                        <li className={cx("main_list_item")}>
                          우리집 서비스 구축(React)
                        </li>
                        <li className={cx("main_list_item")}>
                          전사 공통 디자인 관련 개선
                        </li>
                        <li className={cx("main_list_item")}>
                          알림 서비스 구축(React)
                        </li>
                        <li className={cx("main_list_item")}>
                          기타 다수 유지 보수 업무 수행
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://wav.media/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 북미 서비스 WAV UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2018.03 ~ 2019.05. (
                        {dateCompare("2018-03-01", "2019-05-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%, 서비스 리딩
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        북미 전략형 음악 서비스 구축 프로젝트 참여, 첫 주니어
                        케어 경험
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      네이버 폴라 UI 개발
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2017.04. ~ 2018.03.(
                        {dateCompare("2017-04-01", "2018-03-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%, 서비스 리딩
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        유지 보수 업무 수행
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://cafe.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 카페 UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2016.12. ~ 2017.04.(
                        {dateCompare("2016-12-01", "2017-04-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 50%
                      </span>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://notice.naver.com/notices/cafe/11390?searchValue=%25EB%258C%2580%25ED%2591%259C%2520%25EC%25B9%25B4%25ED%258E%2598&page=1&pageSize=10&newNoticeHour=168&t=d"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 대표 카페 서비스 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://cafe.naver.com/cafesupport/114256"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 상품 글쓰기 개선
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            href="https://cafe.naver.com/cafesupport/113572"
                            className={cx("main_item_link", "type_service")}
                            target="_blank"
                          >
                            🔗 게시글 상세 개선
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          알림 서비스 구축
                        </li>
                        <li className={cx("main_list_item")}>
                          기타 다수 유지 보수 업무 수행
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://kin.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 지식iN UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2016.06. ~ 2016.11.(
                        {dateCompare("2016-06-01", "2016-11-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 50%
                      </span>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://blog.naver.com/PostView.naver?blogId=kin_friend&logNo=220789749827&categoryNo=0&parentCategoryNo=0&viewDate=&currentPage=150&postListTopCurrentPage=&from="
                            target="_blank"
                          >
                            🔗 열심 답변자 프로필 서비스 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://blog.naver.com/kin_friend/220864671184"
                            target="_blank"
                          >
                            🔗 취득 자격 서비스 구축
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          기타 다수 유지 보수 업무 수행
                        </li>
                      </ul>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      <a
                        href="https://post.naver.com/"
                        className={cx("main_title_link", "type_basic")}
                        target="_blank"
                      >
                        🔗 네이버 포스트 UI 개발
                      </a>
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2016.04. ~ 2016.06.(
                        {dateCompare("2016-04-01", "2016-06-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 50%
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        첫 포털 서비스 경험 및 대형 서비스 유지 보수 경험
                      </p>
                      <ul
                        className={cx(
                          "main_data_list",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://post.naver.com/viewer/postView.naver?volumeNo=8717281&memberNo=201497&searchKeyword=%ED%83%91%EA%B8%B0%EC%96%B4%20%ED%8F%AC%EC%8A%A4%ED%8A%B8%20%EB%A7%A4%EA%B1%B0%EC%A7%84&searchRank=1"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 공지
                          </a>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/list.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 구축 - 목록
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/bestbaby.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 베스트 베이비
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/goout.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 고아웃 코리아
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/petzzi.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 펫찌
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/monthlydesign.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 월간디자인
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/cosmopolitan.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 코스모폴리탄
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/travie.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 트래비
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/univ.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 대학내일
                          </a>
                        </li>
                        <li className={cx("main_list_item")}>
                          <a
                            className={cx("main_item_link", "type_service")}
                            href="https://m.post.naver.com/magazineContest/topGear.naver"
                            target="_blank"
                          >
                            🔗 공모전 매거진 - 탑기어
                          </a>
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2015.06. ~ 2016.04.(
                    {dateCompare("2015-06-01", "2016-04-01")})
                  </em>

                  <strong className={cx("main_head_title", "type_company")}>
                    <a
                      href="https://cordial.co.kr/"
                      className={cx("main_title_link")}
                      target="_blank"
                    >
                      🔗 코디얼
                    </a>
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    UI 개발 / 사원
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      롯데마트 차세대 포털 그룹웨어 구축
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2015.06. ~ 2015.11.(
                        {dateCompare("2015-06-01", "2015-11-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 50%
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        첫 그룹웨어 솔루션 구축
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      삼표그룹 그룹웨어 구축
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2015.08. ~ 2015.10.(
                        {dateCompare("2015-08-01", "2015-10-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%
                      </span>
                    </dd>
                  </dl>
                </div>
              </li>
              <li className={cx("main_list_item")}>
                <div className={"main_item_head"}>
                  <em className={cx("main_head_text", "type_basic")}>
                    2013.10. ~ 2015.06.(
                    {dateCompare("2013-10-01", "2015-06-01")})
                  </em>

                  <strong className={cx("main_head_title", "type_company")}>
                    이니셔티브식스
                  </strong>

                  <span className={cx("main_head_text", "type_position")}>
                    UI 개발 / 사원
                  </span>
                </div>

                <div className={cx("main_item_result")}>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      회사 홈페이지 리뉴얼
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2015.04. ~ 2015.05.(
                        {dateCompare("2015-04-01", "2015-05-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 100%
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        첫 단독 프로젝트
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      알리안츠 생명 리뉴얼
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2015.02. ~ 2015.04.(
                        {dateCompare("2015-02-01", "2015-04-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 40%
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        첫 금융권 프로젝트 및 대형 프로젝트 경험
                      </p>
                    </dd>
                  </dl>
                  <dl className={cx("main_result_description")}>
                    <dt className={cx("main_description_title")}>
                      농식품 정보누리 포털 구축
                    </dt>
                    <dd className={cx("main_description_data")}>
                      <span className={cx("main_data_text", "type_basic")}>
                        2013.10. ~ 2013.12.(
                        {dateCompare("2013-10-01", "2013-12-01")})
                      </span>
                      <span className={cx("main_data_text", "type_basic")}>
                        기여도 : 20%
                      </span>
                      <p
                        className={cx(
                          "main_data_text",
                          "type_detail",
                          "type_dot"
                        )}
                      >
                        신입으로 입사 후 첫 프로젝트 경험
                      </p>
                    </dd>
                  </dl>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
